import HttpClient from "../lib/http-client";

/**
 * @memberof Services
 */
class AccountService extends HttpClient {
  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns {object}
   */
  async login(username, password) {
    const data = await this.http.post("/auth/login", {
      username,
      password,
    });
    return data;
  }

  /**
   *
   * @param {string} email
   * @returns {object}
   */
  async sendResetPassword(values) {
    const data = await this.http.post("/auth/reset/send", values);
    return data;
  }

  /**
   *
   * @param {string} userId
   * @returns {boolean}
   */
  async resendInvite(userId) {
    const apiUrl = `/auth/invite/${userId}`;
    return await this.http.post(apiUrl);
  }

  /**
   * @param {string} email
   * @param {string} token
   * @returns {object}
   */
  async validateResetPassword(email, token) {
    const params = {
      token,
      email,
    };
    console.log(email, token);
    const data = await this.http.get(`/auth/reset/password`, { params });
    return data;
  }

  /**
   *
   * @param {string} token
   * @param {string} password
   * @returns {object}
   */
  async resetPassword(payload) {
    const data = await this.http.post("/auth/reset/password", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async impersonate() {
    const data = await this.http.get("client-services/impersonate/login");
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async generateCode(payload) {
    const data = await this.http.post("/auth/generate-code", payload);
    return data;
  }
  /**
   *
   * @returns {URL} redirectUrl
   */
  async proprofsAuth(payload) {
    const data = await this.http.post("/auth/proprofs/login", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async verify_self_signup() {
    const data = await this.http.get("/auth/self_signup/verify");
    return data;
  }
}

export default AccountService;
