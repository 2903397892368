import clsx from "clsx";
import React, { useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { ChartFilter } from "../../../components/custom/forms";
import { CeDataboxes } from "./ce.databoxes";
import { CeTopCareers } from "./ce.top-careers";
import { DashboardChart } from "./dashboard.chart";
import { Product } from "../../../models";

import "./dashboard.insights.scss";

export function CeInsights({
  userInfo,
  orgCode,
  generalStats,
  dailyStats,
  onApplyFilters,
  topCareers,
  classroomFilterEnabled,
  organizationFilterEnabled,
  className,
  onExportData,
  exportDataDisabled,
  customExplorersFn,
  classroom,
}) {
  const [categorySelected, setCategorySelected] = useState();

  return (
    <div className={clsx("dashboard-insights", className)}>
      <div className="subheader">
        <ChartFilter
          onApplyFilters={onApplyFilters}
          userInfo={userInfo}
          orgCode={orgCode}
          classroomEnabled={classroomFilterEnabled}
          organizationEnabled={organizationFilterEnabled}
          product={Product.CE}
        />
        <Button
          primary
          size="small"
          icon={["fa-light", "file-export"]}
          onClick={onExportData}
          disabled={exportDataDisabled}
        >
          Export Data (.csv)
        </Button>
      </div>
      <CeTopCareers
        topCareers={topCareers}
        activeExplorers={generalStats?.activeExplorers}
        breadcrumbLabel={"Dashboard"}
        customExplorersFn={customExplorersFn}
        classroom={classroom}
      ></CeTopCareers>
      <CeDataboxes
        onCategoryChange={setCategorySelected}
        data={generalStats}
      ></CeDataboxes>
      <DashboardChart
        className="chart"
        dailyStats={dailyStats}
        filterSelected={categorySelected}
      />
    </div>
  );
}
