import {
  NotificationStack,
  NotificationType,
  Tab,
  TabHeader,
  TabHeaders,
  Tabs,
} from "@transfr-inc/dashboard-components";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";

import { ClassroomHeader } from "./components/classroom.header";
import { ClassroomInstructors } from "./components/classroom.instructors";
import { ClassroomToolbar } from "./components/classroom.toolbar";
import { ClassroomGradebookTab } from "./views/classroom.gradebook.tab";
import { ClassroomProgressTab } from "./views/classroom.progress.tab";
import { ClassroomUsers } from "./views/classroom.users";
import { ClassroomQRTab } from "./views/classroom.qr.tab";

import container from "../../../container";
import { useHistoryState } from "../../../lib/hooks";
import { useApiRequest } from "../../../lib/http-client";
import { ClassroomMenuBuilderTab } from "./views/classroom.menu-builder.tab";

import { Product } from "../../../models";
import { getUserLabelsByProduct } from "./utils/labels";

import "./index.scss";

const ClassroomDetailsTabs = {
  Progress: "progress",
  Gradebook: "gradebook",
  MenuBuilder: "menuBuilder",
  QR: "qr",
};

export default ({ responsiveStyles }) => {
  const [classroom, setClassroom] = useState();
  const [userTabTitle, setUserTabTitle] = useState();
  const [notifications, setNotifications] = useState();
  const [gradebookEnabled, setGradebookEnabled] = useState();
  const [showMenuBuilder, setShowMenuBuilder] = useState();
  const { classroomId, breadcrumbLabel, selectedTab } =
    useLocation()?.state ?? {};
  const { classroomService } = container;
  const { features } = useStoreState((state) => state.account);
  const { editClassroomEnabled, deleteClassroomEnabled } = features;
  const { menuBuilderEnabled, selfSignupEnabled } = useStoreState(
    (state) => state.app
  );
  const { addStateProps } = useHistoryState();

  const {
    loading: loadingUsers,
    response: users = [],
    sendRequest: getUsers,
  } = useApiRequest(() => classroomService.getClassroomTrainees(classroomId));

  const { response } = useApiRequest(() =>
    classroomService.getClassroomDetails(classroomId)
  );

  const onEditClassroom = (name) => {
    return classroomService.updateClassroomName(classroomId, name).then(() => {
      setClassroom({ ...classroom, name });
      showSuccessNotification(
        <>
          The details for <strong>{name}</strong> have been updated.
        </>
      );
    });
  };

  const onDeleteClassroom = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });
  };

  const onUsersUpdate = ({ message }) => {
    showSuccessNotification(message);
    getUsers();
  };

  const onInstructorsUpdate = ({ success, error }) => {
    success && showSuccessNotification(success);
    error && showErrorNotification(error);
  };

  const showSuccessNotification = (message) => {
    addNotification({
      className: "classroom-notification",
      type: NotificationType.success,
      icon: ["fa-solid", "circle-check"],
      message,
    });
  };

  const showErrorNotification = (message) => {
    addNotification({
      className: "classroom-notification",
      type: NotificationType.error,
      message,
    });
  };

  const addNotification = (notification) => {
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  const setTabState = (tabName) => addStateProps({ selectedTab: tabName });

  useEffect(() => {
    if (response) {
      const classroomProduct = response.products[0];
      const isTsClassroom = classroomProduct === Product.TS;
      const { userTabTitle } = getUserLabelsByProduct(classroomProduct);
      setClassroom(response);
      setUserTabTitle(userTabTitle);
      setGradebookEnabled(isTsClassroom);
      setShowMenuBuilder(
        isTsClassroom && menuBuilderEnabled && response.classroomMenuBuilder
      );
    }
  }, [response]);

  const pageHeader = (
    <ClassroomHeader classroom={classroom} breadcrumbLabel={breadcrumbLabel}>
      <ClassroomToolbar
        classroom={classroom}
        hasEdit={editClassroomEnabled}
        hasDelete={deleteClassroomEnabled}
        onEditClassroom={onEditClassroom}
        onDeleteClassroom={onDeleteClassroom}
      ></ClassroomToolbar>
    </ClassroomHeader>
  );

  return (
    classroom && (
      <PageLayout className={"trainee-detail-page"} header={pageHeader}>
        <div className="classroom-details-page">
          <ClassroomInstructors
            classroom={classroom}
            enableEdit={editClassroomEnabled}
            onInstructorsUpdate={onInstructorsUpdate}
          ></ClassroomInstructors>
          <div className="classroom-tabs">
            <Tabs
              staticHeaders
              selectedTabName={selectedTab ?? ClassroomDetailsTabs.Progress}
            >
              <TabHeaders className="classroom-tabs-headers">
                <TabHeader
                  name={ClassroomDetailsTabs.Progress}
                  onTabClick={setTabState}
                >
                  Progress
                </TabHeader>
                <TabHeader name={userTabTitle} onTabClick={setTabState}>
                  {userTabTitle}
                  <span className="badge">
                    {loadingUsers ? "..." : users.length}
                  </span>
                </TabHeader>
                {selfSignupEnabled && (
                  <TabHeader
                    name={ClassroomDetailsTabs.QR}
                    onTabClick={setTabState}
                  >
                    {"Quick Sign Up (QR)"}
                  </TabHeader>
                )}
                {gradebookEnabled && (
                  <TabHeader
                    name={ClassroomDetailsTabs.Gradebook}
                    onTabClick={setTabState}
                  >
                    Gradebook
                  </TabHeader>
                )}
                {showMenuBuilder && (
                  <TabHeader
                    name={ClassroomDetailsTabs.MenuBuilder}
                    onTabClick={setTabState}
                  >
                    Menu Builder
                  </TabHeader>
                )}
              </TabHeaders>
              <Tab
                name={ClassroomDetailsTabs.Progress}
                className="classroom-tabs-content"
              >
                <ClassroomProgressTab classroom={classroom} users={users} />
              </Tab>
              <Tab name={userTabTitle} className="classroom-tabs-content">
                <ClassroomUsers
                  responsiveStyles={responsiveStyles}
                  users={users}
                  getUsers={getUsers}
                  classroom={classroom}
                  enableEdit={editClassroomEnabled}
                  onUsersUpdate={onUsersUpdate}
                  showSuccessNotification={showSuccessNotification}
                ></ClassroomUsers>
              </Tab>
              <Tab
                name={ClassroomDetailsTabs.QR}
                className="classroom-tabs-content"
              >
                <ClassroomQRTab classroom={classroom} />
              </Tab>
              {gradebookEnabled && (
                <Tab
                  name={ClassroomDetailsTabs.Gradebook}
                  className="classroom-tabs-content"
                >
                  <ClassroomGradebookTab users={users} classroom={classroom} />
                </Tab>
              )}
              {showMenuBuilder && (
                <Tab
                  name={ClassroomDetailsTabs.MenuBuilder}
                  className="classroom-tabs-content"
                >
                  <ClassroomMenuBuilderTab
                    classroom={classroom}
                    enableEdit={editClassroomEnabled}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
          <div className="notification-container">
            {notifications?.length && (
              <NotificationStack
                notifications={notifications}
                closable
                onClose={onCloseNotification}
              ></NotificationStack>
            )}
          </div>
        </div>
      </PageLayout>
    )
  );
};
