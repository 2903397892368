import React, { useState } from "react";
import clsx from "clsx";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { ChartFilter } from "../../../components/custom/forms";

import { DashboardChart } from "./dashboard.chart";
import { DashboardDataboxes } from "./dashboard.databoxes";

import "./dashboard.insights.scss";
import { Product } from "../../../models";
export function DashboardInsights({
  className,
  userInfo,
  orgCode,
  generalStats,
  dailyStats,
  onExportData,
  exportDataDisabled,
  classroomFilterEnabled,
  organizationFilterEnabled,
  moduleFilterEnabled,
  onApplyFilters,
  filterCount,
}) {
  const [categorySelected, setCategorySelected] = useState();

  return (
    <div className={clsx("dashboard-insights", className)}>
      <div className="subheader">
        <ChartFilter
          onApplyFilters={onApplyFilters}
          userInfo={userInfo}
          orgCode={orgCode}
          classroomEnabled={classroomFilterEnabled}
          organizationEnabled={organizationFilterEnabled}
          moduleEnabled={moduleFilterEnabled}
          product={Product.TS}
          filterCount={filterCount}
        />
        <Button
          primary
          icon={["fa-light", "file-export"]}
          onClick={onExportData}
          disabled={exportDataDisabled}
        >
          Export Data (.csv)
        </Button>
      </div>
      <DashboardDataboxes
        onCategoryChange={setCategorySelected}
        data={generalStats?.response}
      ></DashboardDataboxes>
      <DashboardChart
        className="chart"
        dailyStats={dailyStats}
        filterSelected={categorySelected}
      />
    </div>
  );
}
