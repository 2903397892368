import { csvFormatRows } from "d3-dsv";
import HttpClient from "../lib/http-client";
/**
 * @memberof Services
 */
class UserService extends HttpClient {
  async getMultiOrgUsers(organizationCode) {
    return this.http.get(`/multi-organization/${organizationCode}/users`);
  }

  async getOrganizationUsers(organizationCode) {
    return this.http.get(`/organization/${organizationCode}/users`);
  }

  async getOrganizationUsersForInstructor(organizationCode, userId) {
    return this.http.get(
      `/organization/${organizationCode}/instructor/${userId}/users`
    );
  }

  async getOrganizationUsersForRole(organizationCode, roleId) {
    return await this.http.get(
      `/organization/${organizationCode}/users/role/${roleId}`
    );
  }

  async getOrganizationUsersForRoles(organizationCode, roleIds) {
    return await this.http.post(
      `/organization/${organizationCode}/users/roles`,
      { roleIds }
    );
  }

  async getTraineeInstructorCountForOrg(organizationCode) {
    return await this.http.get(`/organization/${organizationCode}/user/count`);
  }

  async getUserClassrooms(userId) {
    const apiUrl = `/user/trainee/${userId}/classrooms`;
    return await this.http.get(apiUrl);
  }

  async createNewUser(user, orgCode, products) {
    let payload = { ...user };
    if (products) {
      payload.productIds = products;
    }
    const apiUrl = `/user/organization/${orgCode}/`;

    const data = await this.http.post(apiUrl, payload);
    return data;
  }

  async getUserDetails(userId) {
    const apiUrl = `/user/${userId}/`;
    return this.http.get(apiUrl);
  }

  async updateUserMetadata({
    userId,
    email,
    firstName,
    lastName,
    username,
    roleId,
  }) {
    const apiUrl = `/user/${userId}/`;
    const body = { email, lastName, firstName, username, roleId };
    return this.http.put(apiUrl, body);
  }

  async updateUserPreferences({ userId, avatarId }) {
    const apiUrl = `/user/${userId}/preferences`;
    const body = { avatarId };
    return this.http.put(apiUrl, body);
  }

  async updateUserNotificationPreferences(userId, preferences) {
    const apiUrl = `/user/${userId}/notification-preferences`;
    return this.http.put(apiUrl, preferences);
  }

  async getUploadErrors(bulkUploadId) {
    const apiUrl = `/user/trainee/errors/${bulkUploadId}`;
    return this.http.get(apiUrl);
  }

  async deleteUsers({ userIds, roleId }) {
    const apiUrl = `/user/bulk/deactivation`;
    return this.http.put(apiUrl, { userIds, roleId });
  }

  async uploadUsers(file, organizationCode) {
    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append("file", file);

    formData.append("organization_code", organizationCode);

    const apiUrl = "/user/trainee/upload";

    return this.http.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 300000,
    });
  }

  async uploadUsersAsync(file, organizationCode, classroomId) {
    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append("file", file);

    formData.append("organization_code", organizationCode);

    if (classroomId) {
      formData.append("classroom_id", classroomId);
    }

    const apiUrl = "/user/trainee/async/upload";

    return this.http.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 300000,
    });
  }

  async getErrorsCsv(parsedData) {
    if (parsedData.errors.length === 0) {
      return "File imported successfully with no errors.";
    }

    const headerRowsArray = [parsedData.header_items];
    const dataArray = parsedData.errors.map((error) => {
      const values = parsedData.columns.map((column) => {
        return error.values[column.property_name];
      });

      values.push(error.message);
      return [...values];
    });

    const csvString = csvFormatRows([...headerRowsArray, ...dataArray]);
    return csvString;
  }

  async updatePassword(userIds, password) {
    const payload =
      userIds?.length > 1
        ? { users: userIds.map((u) => ({ userId: u, password: password })) }
        : { userId: userIds[0], password: password };

    const apiUrl = userIds?.length > 1 ? `/user/passwords` : `/user/password`;
    return this.http.put(apiUrl, payload);
  }

  async getCustomCategories(instructor_id) {
    const apiUrl = `/user/instructor/${instructor_id}/custom_categories`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  async sendTrekInvites(users, classroomId) {
    const apiUrl = `user/trek/invitations`;
    const data = {
      userIds: users,
      classroomId,
    };
    return await this.http.post(apiUrl, data);
  }

  async validateTrekCompleteToken(userId, token) {
    const apiUrl = `/user/${userId}/trek/complete-token`;
    return await this.http.put(apiUrl, { token });
  }
}

export default UserService;
