import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";

import { Tab, TabHeaders, Tabs } from "@transfr-inc/dashboard-components";

import { ProductTabHeader } from "../../../components/custom/tabs/product-tab-header";
import { MyWorkTab } from "../components/my-work.tab";
import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";
import "./index.scss";
import { Product } from "../../../models";

export default () => {
  const { currentUser } = useStoreState((store) => store.account);
  const { ceStudentExperienceEnabled } = useStoreState((state) => state.app);

  const { userId, organizationCode } = currentUser;
  const [isLoading, setIsLoading] = useState(true);
  const [trainee, setTrainee] = useState();
  const [classrooms, setClassrooms] = useState();
  const { userService, organizationService } = container;

  const { loading: loadingTrainee } = useApiRequest(() =>
    userService.getUserDetails(userId).then(setTrainee)
  );

  const { response: organization, sendRequest: getOrgDetails } = useApiRequest(
    () => organizationService.getOrganizationDetails(organizationCode),
    false
  );

  const { loading: loadingClassrooms } = useApiRequest(() =>
    userService
      .getUserClassrooms(userId)
      .then((results) =>
        results.filter(
          (r) =>
            (ceStudentExperienceEnabled && r.products.includes(Product.CE)) ||
            r.products.includes(Product.TS)
        )
      )
      .then(setClassrooms)
  );

  useEffect(() => {
    !loadingTrainee && getOrgDetails();
  }, [loadingTrainee]);

  useEffect(() => {
    setIsLoading(loadingClassrooms || loadingTrainee || !organization);
  }, [loadingClassrooms, loadingTrainee, organization]);

  const getSelectedTab = () => {
    return classrooms[0]?.classroomId;
  };

  return (
    !isLoading && (
      <PageLayout className={"trainee-detail-page"}>
        <div className="trainee-detail-content">
          <div className="trainee-classrooms-header">
            <h2>My Work</h2>
          </div>
          {classrooms?.length > 0 ? (
            <Tabs
              className="trainee-detail-tabs"
              selectedTabName={isLoading ? "" : getSelectedTab()}
            >
              <TabHeaders className="classroom-tabs">
                {classrooms?.map((c) => (
                  <ProductTabHeader
                    key={c.classroomId}
                    name={c.classroomId}
                    productId={c.products[0]}
                    text={c.name}
                  />
                ))}
              </TabHeaders>
              {classrooms?.map((classroom) => (
                <Tab
                  key={classroom.classroomId}
                  name={classroom.classroomId}
                  className={"classroom-tab"}
                >
                  <MyWorkTab
                    trainee={trainee}
                    classroom={classroom}
                    organization={organization}
                  />
                </Tab>
              ))}
            </Tabs>
          ) : (
            <div className="no-classrooms-container">
              This trainee is not enrolled in a classroom.
            </div>
          )}
        </div>
      </PageLayout>
    )
  );
};
