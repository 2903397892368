import React from "react";

import { Loader, TextTooltip } from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { useApiRequest } from "../../../../lib/http-client";
import container from "../../../../container";

import "./classroom.qr.tab.scss";

export const ClassroomQRTab = ({ classroom }) => {
  const { classroomId } = classroom ?? {};
  const { classroomService } = container;

  const { loading, response: qrData } = useApiRequest(() =>
    classroomService.getQuickSignupQRCode(classroomId)
  );

  const qrImgString = `data:image/png;base64,${qrData?.code}`;
  const infoText = "Copied to clipboard";

  const copyLink = async () => {
    try {
      // eslint-disable-next-line no-undef
      await navigator.clipboard.writeText(qrData.link);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="classroom-qr-main-container">
      <div className="qr-signup-text">
        Quickly signup students for this classroom.
      </div>
      {loading && <Loader overlay></Loader>}
      <img className="qr-img" src={qrImgString} />
      <TextTooltip className="info" text={infoText} trigger="click">
        <Button icon={["fa-light", "fa-link"]} onClick={copyLink} size="small">
          Copy Link
        </Button>
      </TextTooltip>
    </div>
  );
};
