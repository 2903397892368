import React from "react";
import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import { formatDateLocaleMonth } from "@transfr-inc/dashboard-components/utils";

import { DashboardHeader, OrgsShortcuts, UploadFooter } from "./components";

import { ProductTabs } from "./views/multi-org/product.tabs";

import "./dashboard.scss";

export default ({ className }) => {
  const { currentUser, products } = useStoreState((store) => store.account);
  const { firstName, organizationName } = currentUser;

  return (
    <div className={clsx("dashboard", className)}>
      <div className="dashboard-container">
        <DashboardHeader
          title="Dashboard"
          subtitle={
            <div>
              Hi <strong>{firstName}</strong>! Here&apos;s your{" "}
              <strong>{organizationName}</strong> update for{" "}
              {formatDateLocaleMonth()}.
            </div>
          }
        ></DashboardHeader>
        <ProductTabs products={products}></ProductTabs>
        <UploadFooter />
      </div>
      <div className="side-shortcuts">
        <OrgsShortcuts></OrgsShortcuts>
      </div>
    </div>
  );
};
