import clsx from "clsx";
import React, { useState } from "react";

import { ChartFilter } from "../../../components/custom/forms";
import { Product } from "../../../models";
import { DashboardChart } from "./dashboard.chart";
import { TopCareersSelected } from "../../../components/top-careers-selected";
import { TrekDataboxes } from "./trek.databoxes";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@transfr-inc/dashboard-components";
import { TrekFeedbackForm } from "../../../models";

export function TrekInsights({
  userInfo,
  orgCode,
  generalStats,
  dailyStats,
  onApplyFilters,
  classroomFilterEnabled,
  organizationEnabled,
  className,
  topCareers,
  isLoadingTopCareers,
  users,
  onExportData,
  exportDataDisabled,
}) {
  const [categorySelected, setCategorySelected] = useState();

  return (
    <div className={clsx("dashboard-insights", className)}>
      <div className="subheader">
        <ChartFilter
          onApplyFilters={onApplyFilters}
          userInfo={userInfo}
          orgCode={orgCode}
          classroomEnabled={classroomFilterEnabled}
          organizationEnabled={organizationEnabled}
          product={Product.TRK}
        />
        <div className="right-hand-buttons">
          <Button size="small">
            <Link to={TrekFeedbackForm.url} underline={false} isExternal>
              Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Button>
          <Button
            primary
            size="small"
            icon={["fa-light", "file-export"]}
            onClick={onExportData}
            disabled={exportDataDisabled}
          >
            Export Data (.csv)
          </Button>
        </div>
      </div>
      <TopCareersSelected
        breadcrumbLabel="dashboard"
        topCareers={topCareers}
        isLoading={isLoadingTopCareers}
        activeUsers={generalStats?.totalActiveUsers}
        users={users}
      />
      <TrekDataboxes
        onCategoryChange={setCategorySelected}
        data={generalStats}
      />
      <DashboardChart
        className="chart"
        dailyStats={dailyStats}
        filterSelected={categorySelected}
      />
    </div>
  );
}
