import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { OverflowText } from "@transfr-inc/dashboard-components";

import "./careers-selected.list.scss";

export const CareersSelectedList = ({ careers, onCareerSelected }) => {
  return (
    <div className="careers-selected-list-container">
      {careers?.map((career, index) => (
        <button
          className="career-item"
          key={career.transfrId}
          onClick={() => onCareerSelected(career)}
        >
          <div className="career-item-index">{index + 1}</div>
          <div className="career-item-info">
            <OverflowText className="subtitle" text={career.clusterName}>
              {career.cluster.name}
            </OverflowText>
            <OverflowText className="main-title" text={career.occupationName}>
              {career.title}
            </OverflowText>
          </div>
          <FontAwesomeIcon icon="fa-light fa-chevron-right" />
        </button>
      ))}
    </div>
  );
};
