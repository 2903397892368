import { thunk, action } from "easy-peasy";

export default ({ accountService }) => {
  return {
    loadState: {
      active: true,
      error: false,
      blocking: true,
      success: false,
    },

    saveState: {
      error: false,
      blocking: false,
      success: false,
    },

    model: {
      token: "",
      email: "",
    },

    setModel: action((state, model) => {
      state.model = model;
    }),

    setLoadState: action((state, payload) => {
      state.loadState = { ...state.loadState, ...payload };
      console.log(state.loadState);
    }),

    setSubmitSuccess: action((state) => {
      state.saveState.blocking = false;
      state.saveState.success = true;
      state.loadState.active = false;
      state.saveState.error = false;
    }),

    setSubmitFailed: action((state) => {
      state.saveState.blocking = false;
      state.saveState.success = false;
      state.saveState.error = true;
    }),

    setSubmitProgress: action((state) => {
      state.saveState.error = false;
      state.saveState.blocking = true;
    }),

    resetSubmit: action((state) => {
      state.saveState.error = false;
    }),

    onPageLoad: thunk(async (actions, payload) => {
      try {
        actions.setLoadState({ blocking: true });
        await accountService.validateResetPassword(
          payload.email,
          payload.token
        );
        actions.setModel(payload);
        actions.setLoadState({ success: true, blocking: false });
      } catch (error) {
        console.error(error);
        actions.setLoadState({ blocking: false, error });
      }
    }),

    onSubmit: thunk(async (actions, payload, { getState }) => {
      const { model } = getState();
      try {
        actions.setSubmitProgress();
        const values = { token: model.token, password: payload.password };
        if (payload.termsAndConditionsVersion) {
          values.termsAndConditionsVersion = payload.termsAndConditionsVersion;
        }
        await accountService.resetPassword(values);
        actions.setSubmitSuccess();
      } catch (error) {
        console.error(error);
        actions.setSubmitFailed({ blocking: false, error });
      }
    }),
  };
};
