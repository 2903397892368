import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { TraineeClassroomTsTab } from "./trainee.classroom.ts.tab";
import { TraineeClassroomCeTab } from "./trainee.classroom.ce.tab";
import { TraineeClassroomTrekTab } from "./trainee.classroom.trek.tab";

import { Product } from "../../../../models";
import { ProductDropDownList } from "../../../../components/custom/dropdowns/product-dropdown-list";

import { useHistoryState } from "../../../../lib/hooks";

export function TraineeClassroomTab({
  trainee,
  classroom,
  organization,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const { selectedProduct } = useLocation()?.state || {};
  const { products } = classroom;
  const [selectedProductId, setSelectedProductId] = useState(
    selectedProduct ?? products[0]
  );
  const { addStateProps } = useHistoryState();

  const renderClassroomTab = (classroomProduct) => {
    switch (classroomProduct) {
      case Product.TS:
        return (
          <TraineeClassroomTsTab
            trainee={trainee}
            classroom={classroom}
            organization={organization}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.CE:
        return (
          <TraineeClassroomCeTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.TRK:
        return (
          <TraineeClassroomTrekTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
    }
  };

  const onProductSelected = ({ id }) => {
    setSelectedProductId(id);
    addStateProps({ selectedProduct: id });
  };

  return (
    <>
      {products.length > 1 && (
        <div className="product-select">
          <h2 className="product-select-label">Product</h2>
          <ProductDropDownList
            products={products}
            className="classroom-product-dropdown"
            onProductSelected={onProductSelected}
            defaultProduct={selectedProductId}
            productImages
          ></ProductDropDownList>
        </div>
      )}
      {renderClassroomTab(selectedProductId)}
    </>
  );
}
