import React, { useState, useEffect } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  OverflowText,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./trainee.shortcut-code.scss";
import container from "../../../../container";

export function TraineeShortcutCode({
  categories,
  loId,
  leId,
  simName,
  onShowNotification,
  classroom,
  user,
  onCodeGenerated,
  codesGenerated,
}) {
  const { accountService } = container;
  const [loading, setLoading] = useState();

  const updateStyling = () => {
    let specificRow = document.getElementById(loId);
    specificRow.style.height = "auto";
    specificRow.style.setProperty("height", "auto", "important");
    specificRow.style.minHeight = "54px";
    specificRow.style.setProperty("min-height", "54px");
  };

  async function fetchCodes() {
    setLoading(true);
    try {
      let payload = {
        userId: user.userId,
        classroomId: classroom.classroomId,
        simulationId: leId,
      };
      let codes = [];
      for (const category of categories) {
        payload.categoryCode = category.categoryCode;
        let result = await accountService.generateCode(payload);
        codes.push({
          name: category.categoryName,
          code: result.code,
        });
      }

      updateStyling();
      onCodeGenerated(leId, codes);
      onShowNotification(
        NotificationType.success,
        `Code generated for ${simName}. You have 30 minutes to enter this single use code into your headset.`
      );
    } catch {
      onShowNotification(
        NotificationType.error,
        "Something went wrong. Please try again."
      );
    }
    setLoading();
  }

  useEffect(() => {
    codesGenerated[leId].generated && updateStyling();
  }, []);

  return (
    <div className="shortcut-code-column">
      {codesGenerated[leId] && codesGenerated[leId].generated ? (
        <div className="categories-section">
          {codesGenerated[leId].codes.map((cat, i) => (
            <div key={i} className={"category-code"}>
              <OverflowText className={"category"} text={cat.name}>
                {cat.name}
              </OverflowText>
              <div className={"code"}>{cat.code}</div>
            </div>
          ))}
        </div>
      ) : (
        <Button onClick={fetchCodes} className="plus-button" disabled={loading}>
          <FontAwesomeIcon icon="fa-regular fa-plus"></FontAwesomeIcon>
        </Button>
      )}
    </div>
  );
}
