import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import {
  ButtonGroup,
  Input,
  OrFormDivider,
} from "@transfr-inc/dashboard-components/forms";

import {
  addDays,
  formatDate,
  formatDateDashes,
} from "@transfr-inc/dashboard-components/utils";

import { useStoreState } from "easy-peasy";
import "react-datepicker/dist/react-datepicker.css";
import "./date-range.selector.scss";

const stringToDate = (str) => {
  const [year, month, day] = str.split("-");
  return new Date(year, month - 1, day);
};

export const DateRangeSelector = ({
  onDateRangeChange,
  showDivider,
  className,
  setCalendarOpen,
  calendarOpen,
  setViewedDates,
  startInput,
  endInput,
}) => {
  const defaultDaysToExpand = 30;
  const defaultStartDate = new Date("01/02/2019");
  const currentDate = new Date();
  const dateRegex = /^(?:\d{2}\/){2}\d{4}$/;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [viewedStartDate, setViewedStartDate] = useState();
  const [viewedEndDate, setViewedEndDate] = useState();
  const [disableInput, setDisableInput] = useState();

  const [datepickerConfig, setDatepickerConfig] = useState();
  const [filterSelected, setFilterSelected] = useState();
  const wrapperRef = useRef();

  const { lastDateRange, lastDateFilter } = useStoreState(
    (store) => store.date
  );

  const selected = useMemo(() => {
    if (datepickerConfig?.selectsStart) {
      return startDate;
    }
    if (datepickerConfig?.selectsEnd) {
      return endDate;
    }

    return startDate;
  }, [endDate, startDate, datepickerConfig]);

  const onClickOutsideDatepicker = () => {
    closeDatepicker();
  };

  const chartDateFilterButtons = [
    {
      label: "30 days",
      onClick: () => expandDateRange(30),
      id: 30,
    },
    {
      label: "60 days",
      onClick: () => expandDateRange(60),
      id: 60,
    },
    {
      label: "1 year",
      onClick: () => expandDateRange(365),
      id: 365,
    },
    {
      label: "All",
      onClick: () => handleAllDates("all"),
      id: "all",
    },
  ];

  const expandDateRange = (daysToAdd) => {
    const currentDate = new Date();
    const startDate = addDays(currentDate, daysToAdd * -1);

    setDisableInput();
    setStartDate(startDate);
    setEndDate(currentDate);
    setFilterSelected(daysToAdd);
    closeDatepicker();
    onDateRangeChangeHandler(startDate, currentDate, daysToAdd);
  };

  const handleAllDates = (id) => {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - defaultStartDate.getTime();
    let dayDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
    const startDate = addDays(currentDate, dayDifference * -1);

    setDisableInput(true);
    setStartDate(startDate);
    setEndDate(currentDate);
    setFilterSelected(id);
    closeDatepicker();
    onDateRangeChangeHandler(startDate, currentDate, dayDifference);
  };

  useEffect(() => {
    checkLastDateSettings();
  }, []);

  const checkLastDateSettings = () => {
    if (lastDateFilter) {
      if (lastDateFilter > 365) {
        return handleAllDates("all");
      }
      expandDateRange(lastDateFilter);
    } else if (lastDateRange) {
      const lastStartDate = stringToDate(lastDateRange.startDate);
      const lastEndDate = stringToDate(lastDateRange.endDate);
      setStartDate(lastStartDate);
      setEndDate(lastEndDate);
      onDateRangeChangeHandler(lastStartDate, lastEndDate);
    } else {
      expandDateRange(defaultDaysToExpand);
    }
  };

  const onChange = (dateSelected) => {
    if (datepickerConfig?.selectsStart) {
      onStartDateSelected(dateSelected);
    } else {
      onEndDateSelected(dateSelected);
    }
    setFilterSelected();
    setCalendarOpen();
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
    onDateButtonClick({ selectsEnd: true, minDate: date });
    onDateRangeChangeHandler(date, endDate);
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
    setFilterSelected();
    closeDatepicker();
    onDateRangeChangeHandler(startDate, date);
  };

  const onDateRangeChangeHandler = (startDate, endDate, daysToAdd) => {
    const startDateFormatted = formatDateDashes(startDate);
    const endDateFormatted = formatDateDashes(endDate);

    onDateRangeChange(startDateFormatted, endDateFormatted, daysToAdd);
  };

  const closeDatepicker = () => {
    setDatepickerConfig();
    setCalendarOpen();
  };

  const onDateButtonClick = (config) => {
    setDatepickerConfig(config);
    !calendarOpen && setCalendarOpen(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutsideDatepicker();
      }
    }
    // Bind the event listener
    calendarOpen && document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, calendarOpen]);

  const handleDateChange = (date) => {
    setCalendarOpen();
    const inputDate = date.target.value;
    if (dateRegex.test(inputDate)) {
      const [month, day, year] = inputDate.split("/");
      const utcDate = new Date(year, month - 1, day);

      if (datepickerConfig?.selectsStart) {
        setStartDate(utcDate);
        onDateRangeChangeHandler(utcDate, endDate);
      } else if (datepickerConfig?.selectsEnd) {
        setEndDate(utcDate);
        onDateRangeChangeHandler(startDate, utcDate);
      }

      setFilterSelected();
    }
    if (datepickerConfig?.selectsStart) {
      setViewedStartDate(inputDate);
    } else if (datepickerConfig?.selectsEnd) {
      setViewedEndDate(inputDate);
    }
  };

  useEffect(() => {
    if (startDate) {
      setViewedStartDate(formatDate(startDate, true));
    }
    if (endDate) {
      setViewedEndDate(formatDate(endDate, true));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const formattedDate = {
      startDate: viewedStartDate,
      endDate: viewedEndDate,
    };

    setViewedDates(formattedDate);
  }, [viewedEndDate, viewedStartDate]);

  const minDate = useMemo(() => {
    if (datepickerConfig?.selectsStart) {
      return null;
    }
    if (datepickerConfig?.selectsEnd) {
      return startDate;
    }
  }, [datepickerConfig]);

  return (
    <div className={clsx("date-range-container", className)}>
      <div className="date-range-picker-container">
        <div className="buttons">
          <Input
            onClick={() => onDateButtonClick({ selectsStart: true })}
            value={viewedStartDate}
            onChange={handleDateChange}
            ref={startInput}
            disabled={disableInput}
            label="Start Date"
          />
          <div className="to">to</div>
          <Input
            onClick={() =>
              onDateButtonClick({ selectsEnd: true, minDate: startDate })
            }
            value={viewedEndDate}
            onChange={handleDateChange}
            ref={endInput}
            disabled={disableInput}
            label="End Date"
          />
        </div>
        {calendarOpen && (
          <div ref={wrapperRef} className="date-picker-container">
            <DatePicker
              selected={selected}
              onChange={onChange}
              startDate={startDate}
              maxDate={currentDate}
              minDate={minDate}
              dateFormat={"LLLL dd, yyyy"}
              monthsShown={1}
              inline
            />
          </div>
        )}
      </div>
      {showDivider && <OrFormDivider />}
      <div className="group">
        <ButtonGroup
          buttons={chartDateFilterButtons}
          selected={filterSelected}
        />
      </div>
    </div>
  );
};
