import React, { useEffect, useState } from "react";

import { useApiRequest } from "../../../../lib/http-client";

import { Loader } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { InstructorBox } from "./instructor.box";
import { InstructorEmailBox } from "../../../users/trainee-detail/components/instructor.email-box";
import { ShortcutAction } from "../../../../components/shortcuts";
import { AddInstructorModal } from "../modals/add-instructor.modal";
import { RemoveInstructorModal } from "../modals/remove-instructor.modal";

import container from "../../../../container";

import "./classroom.instructors.scss";

const getSuccessMessage = (instructors, classroom) => {
  const { firstName, lastName } = instructors[0].data;
  const { name } = classroom;
  const instructorsLength = instructors.length;

  return instructorsLength > 1 ? (
    <>
      <strong>{instructorsLength}</strong> instructors have been added to{" "}
      <strong>{name}</strong>
    </>
  ) : (
    <>
      <strong>{`${firstName} ${lastName}`}</strong> has been added to{" "}
      <strong>{name}</strong>
    </>
  );
};

export function ClassroomInstructors({
  classroom,
  enableEdit,
  onInstructorsUpdate,
}) {
  const [openAddInstructorModal, setOpenAddInstructorModal] = useState();
  const [openRemoveInstructorModal, setOpenRemoveInstructorModal] = useState();
  const [instructorSelected, setInstructorSelected] = useState();
  const [instructors, setInstructors] = useState();

  const { classroomService } = container;

  const {
    loading,
    response,
    sendRequest: getInstructors,
  } = useApiRequest(() =>
    classroomService.getClassroomInstructors(classroom.classroomId)
  );

  const onRemoveInstructor = (instructor) => {
    setInstructorSelected(instructor);
    setOpenRemoveInstructorModal(true);
  };

  const onRemoveInstructorCancel = () => {
    closeRemoveInstructorModal();
  };

  const onRemoveInstructorConfirmation = (instructor) => {
    const { userId, firstName, lastName } = instructor;
    const { classroomId, name: classroomName } = classroom;

    return classroomService.removeInstructor(classroomId, userId).then(() => {
      setInstructors(instructors.filter((i) => i.userId !== userId));
      const message = (
        <>
          Instructor <strong>{`${firstName} ${lastName}`}</strong> has been
          removed from <strong>{classroomName}</strong>.
        </>
      );
      onInstructorsUpdate({ success: message });
      closeRemoveInstructorModal();
    });
  };

  const onAddInstructor = async (selectedInstructors) => {
    const { classroomId } = classroom;
    const instructorsAdded = [];
    const updateResponse = {};

    try {
      for (const instructor of selectedInstructors) {
        await classroomService.addInstructor(classroomId, instructor.id);
        instructorsAdded.push(instructor);
      }
    } catch (error) {
      console.error(
        `[Instructor Error]: An error occurred while adding instructor`,
        error
      );
      updateResponse.error =
        "Something went wrong while adding instructors. Please try again.";
    }

    setOpenAddInstructorModal();
    updateResponse.success =
      instructorsAdded.length > 0 &&
      getSuccessMessage(instructorsAdded, classroom);
    onInstructorsUpdate && onInstructorsUpdate(updateResponse);
    getInstructors();
    return true;
  };

  const closeRemoveInstructorModal = () => {
    setInstructorSelected();
    setOpenRemoveInstructorModal();
  };

  useEffect(() => {
    response && setInstructors(response);
  }, [response]);

  return (
    <>
      <div className="instructors-container">
        <div className="header">
          <h2>
            Instructors <span className="badge">{instructors?.length}</span>
          </h2>
          {enableEdit && (
            <Button
              icon={["fa-light", "chalkboard-user"]}
              onClick={() => setOpenAddInstructorModal(true)}
              size="small"
            >
              Add Instructor
            </Button>
          )}
        </div>
        <div className="list">
          {loading && <Loader></Loader>}
          {instructors?.length > 0
            ? instructors.map((instructor) => (
                <InstructorBox
                  instructor={instructor}
                  key={instructor.userId}
                  onRemove={onRemoveInstructor}
                  enableRemove={enableEdit}
                ></InstructorBox>
              ))
            : !loading && (
                <ShortcutAction
                  className="add-instructor-shortcut"
                  icon={["fa-light", "person-chalkboard"]}
                  onClick={() => setOpenAddInstructorModal(true)}
                  disabled={!enableEdit}
                >
                  Add an Instructor
                </ShortcutAction>
              )}
        </div>
      </div>
      <AddInstructorModal
        open={openAddInstructorModal}
        classroom={classroom}
        existingInstructors={instructors}
        onAddInstructor={onAddInstructor}
        onCancel={setOpenAddInstructorModal}
        async
      ></AddInstructorModal>
      <RemoveInstructorModal
        open={openRemoveInstructorModal}
        classroom={classroom}
        instructor={instructorSelected}
        onCancel={onRemoveInstructorCancel}
        onRemoveInstructor={onRemoveInstructorConfirmation}
      ></RemoveInstructorModal>
    </>
  );
}
