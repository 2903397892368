import React, { useEffect, useState } from "react";
import { Loader } from "@transfr-inc/dashboard-components";
import {
  Button,
  SearchBox,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";

import { ClassroomUsersMasteryTable } from "../components/classroom.users-mastery.table";
import { AddUserModal } from "../modals/add-user.modal";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { getUserLabelsByProduct } from "../utils/labels";
import { getClassroomUsersMastery } from "../../../../services/insights.utils";

import "./classroom.users.scss";

const getSuccessMessage = (trainees, classroom) => {
  const { firstName, lastName } = trainees[0].data;
  const { name } = classroom;
  const traineesLength = trainees.length;

  return traineesLength > 1 ? (
    <>
      <strong>{traineesLength}</strong> users have been added to{" "}
      <strong>{name}</strong>
    </>
  ) : (
    <>
      <strong>{`${firstName} ${lastName}`}</strong> has been added to{" "}
      <strong>{name}</strong>
    </>
  );
};

export function ClassroomUsers({
  users: data,
  classroom,
  responsiveStyles,
  enableEdit,
  onUsersUpdate,
  getUsers,
  showSuccessNotification,
}) {
  const { classroomId, clientCode: orgCode, products } = classroom;
  const classroomProduct = products[0];
  const [users, setUsers] = useState();
  const [openAddUserModal, setOpenUserModal] = useState();
  const { classroomService, trekService } = container;
  const { addUser, singular, plural } =
    getUserLabelsByProduct(classroomProduct);

  const { loading: loadingCareersExplored, response: careersExplored } =
    useApiRequest(() =>
      trekService.getUsersExportData({
        classroomId: classroom.classroomId,
        options: {
          include_explored_careers: true,
          include_selected_career: true,
        },
      })
    );

  const { loading, response: masteryInfo } = useApiRequest(() =>
    getClassroomUsersMastery(classroom).then(
      (response) => new Map(response.map((r) => [r.userId, r]))
    )
  );

  const nameSearch = useSearch({
    data: users,
    searchKeys: ["firstName", "lastName"],
  });

  const onResultSelected = (option) => {
    setUsers([option]);
  };

  const onNameTermChange = (term) => {
    const results = nameSearch.queryData(term);
    setUsers(results);
  };

  const onTraineeAdd = async (trainees) => {
    for (const t of trainees) {
      await classroomService.addTrainee(classroomId, t.id);
    }

    setOpenUserModal();
    const message = getSuccessMessage(trainees, classroom);
    onUsersUpdate && onUsersUpdate({ message });
    return Promise.resolve();
  };

  useEffect(() => {
    if (!loadingCareersExplored && !loading) {
      if (data && masteryInfo) {
        const usersData = data.map((t) => ({
          ...t,
          ...masteryInfo.get(t.userId),
          isSelected: !!careersExplored[t.userId]?.selectedCareer,
          careersViewed:
            careersExplored[t.userId]?.exploredCareers?.length || 0,
        }));
        setUsers(usersData);
        nameSearch.updateData(usersData);
      } else {
        setUsers(data);
      }
    }
  }, [data, masteryInfo, careersExplored]);

  const addTraineeButton = enableEdit && (
    <>
      <Button
        icon={["fa-light", "head-side-goggles"]}
        onClick={() => setOpenUserModal(true)}
        size="small"
      >
        {addUser}
      </Button>
      <AddUserModal
        open={openAddUserModal}
        orgCode={orgCode}
        onCancel={setOpenUserModal}
        onAddTrainee={onTraineeAdd}
        classroomId={classroomId}
        async
        userLabels={{ singular, plural }}
        classroomProduct={classroomProduct}
        allProducts={products}
      ></AddUserModal>
    </>
  );

  return (
    <div className="classroom-trainees-container">
      <div className="actions">
        <SearchBox
          placeholder={"Search First or Last Name"}
          query={nameSearch.queryData}
          onSearchTermChange={onNameTermChange}
          onResultSelected={onResultSelected}
          customOptionLabel={(r) => `${r?.lastName}, ${r?.firstName}`}
        ></SearchBox>
        {addTraineeButton}
      </div>
      {!users ? (
        <Loader></Loader>
      ) : (
        <ClassroomUsersMasteryTable
          responsiveStyles={responsiveStyles}
          data={users}
          enableEdit={enableEdit}
          classroom={classroom}
          getUsers={getUsers}
          showSuccessNotification={showSuccessNotification}
        ></ClassroomUsersMasteryTable>
      )}
    </div>
  );
}
