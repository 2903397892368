import HttpClient from "../lib/http-client";

export default class IntegrationService extends HttpClient {
  getIntegrationList() {
    const apiUrl = `/integrations/providers`;
    return this.http.get(apiUrl);
  }

  getProviderData(providerId) {
    const apiUrl = `/integrations/providers/${providerId}`;
    return this.http.get(apiUrl);
  }

  createIntegrationConnection(providerId, clientCode, userId, body) {
    const apiUrl = `/integrations/connection/create?provider_id=${providerId}&client_code=${clientCode}&user_id=${userId}`;
    return this.http.post(apiUrl, body);
  }

  getAuthUrl(providerId, connectionId) {
    const apiUrl = `/integrations/authorization_url?provider_id=${providerId}&connection_id=${connectionId}`;
    return this.http.get(apiUrl);
  }

  syncIntegration(connectionId, userId) {
    const apiUrl = `/integrations/connection/${connectionId}/sync?user_id=${userId}`;
    return this.http.post(apiUrl);
  }

  getConnectionStatus(connectionId) {
    const apiUrl = `/integrations/connection/${connectionId}/status`;
    return this.http.get(apiUrl);
  }

  getConnectionsList(clientCode) {
    const apiUrl = `/integrations/connection?client_code=${clientCode}`;
    return this.http.get(apiUrl);
  }

  getProviderIconSquare(provider_id) {
    const apiUrl = `/integrations/icon-square?provider_id=${provider_id}`;
    return this.http.get(apiUrl);
  }

  getProviderIconRect(provider_id) {
    const apiUrl = `/integrations/icon-rect?provider_id=${provider_id}`;
    return this.http.get(apiUrl);
  }

  removeIntegration(connectionId) {
    const apiUrl = `/integrations/connection/${connectionId}`;
    return this.http.delete(apiUrl);
  }
}
