import React, { useState, useEffect } from "react";
import container from "../../../container";
import { useApiRequest } from "../../../lib/http-client";
import { Loader, SlashCircleIcon } from "@transfr-inc/dashboard-components";

import "./my-work.ce.tab.scss";
import { InstructorEmailBox } from "../../users/trainee-detail/components/instructor.email-box";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import { MyWorkCeSimulationsTable } from "./careers/my-work.ce.simulations-table";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import { MyWorkDataboxes } from "./careers/my-work.ce.databoxes";
import { useFilter } from "@transfr-inc/dashboard-components/forms";
import { StatusFilter } from "../models/career-status";
import { StatusDropdownList } from "./careers/my-work-ce.status-dropdown-list";
import ShortcutAction from "../../../components/shortcuts/shortcut.action";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1080,
  },
  "large-panel": {
    minWidth: 1080,
  },
};

const mergeMasteryData = (simulations, masteryData) => {
  const data = [];
  for (const sim of simulations) {
    const mastery = masteryData.get(sim.learningExperienceId);
    if (mastery) {
      data.push({ ...sim, ...mastery, explored: true });
    } else {
      data.push({ ...sim, explored: false });
    }
  }
  return data;
};

const groupDataByOccupation = (data) => {
  const groupedByOccupation = {};
  for (const item of data) {
    const { occupationCode } = item;
    if (!groupedByOccupation[occupationCode]) {
      groupedByOccupation[occupationCode] = [];
    }
    groupedByOccupation[occupationCode].push(item);
  }
  return groupedByOccupation;
};

export function MyWorkCeTab({ trainee, classroom }) {
  const { simulationsService, insightsService, classroomService } = container;
  const [params, containerRef] = useContainerQuery(query);
  const { isDesktop } = useScreenBreakpoint();
  const [instructors, setInstructors] = useState();
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [dataGroupedByOccupation, setDataGroupedByOccupation] = useState();
  const [masteryStats, setMasteryStats] = useState();

  const { response: simulations, loading: loadingSims } = useApiRequest(() =>
    simulationsService.getClassroomSimulations(classroom.classroomId)
  );
  const { response: masteryData, loading: loadingMastery } = useApiRequest(() =>
    insightsService.getUserCEMasteryInfo(trainee?.userId)
  );

  const statusFilter = useFilter({
    data: data,
    queryFn: (data, statusSelected) => {
      if (statusSelected === StatusFilter.All) {
        return data;
      } else if (statusSelected === StatusFilter.CareersExplored) {
        return data.filter((d) => d.explored === true);
      } else if (statusSelected === StatusFilter.CareersNotExplored) {
        return data.filter((d) => d.explored === false);
      } else if (statusSelected === StatusFilter.CareersHighlyRated) {
        return data.filter((d) => d.rating && d.rating >= 4);
      }
    },
  });

  const getUserMasteryStats = () => {
    insightsService
      .getExplorerSummaryStats(trainee.userId)
      .then(setMasteryStats)
      .catch(() => setMasteryStats({}));
  };

  const getInstructors = () => {
    classroomService
      .getClassroomInstructors(classroom.classroomId)
      .then(setInstructors)
      .catch(() => setInstructors({}));
  };

  const onStatusSelected = (status) => {
    setSelectedStatus(status);
    const results = statusFilter.queryData(status);
    setFilteredData(results);
  };

  const initData = (value) => {
    setData(value);
    statusFilter.updateData(value);
    setSelectedStatus(StatusFilter.All);
    setFilteredData(statusFilter.queryData(StatusFilter.All));
  };

  useEffect(() => {
    if (simulations && masteryData) {
      const data = mergeMasteryData(simulations, masteryData);
      initData(data);
      const groupedByOccupation = groupDataByOccupation(simulations);
      setDataGroupedByOccupation(groupedByOccupation);
    }
  }, [simulations, masteryData]);

  useEffect(() => {
    if (classroom) {
      getUserMasteryStats();
      getInstructors();
    }
    return () => insightsService.cancelRequest();
  }, []);

  return (
    <>
      {(loadingSims || loadingMastery) && <Loader overlay />}
      <div className="insights-section" ref={containerRef}>
        <div className="insights-header">
          <div className="title-section">
            <h2 className="title">
              {instructors?.length > 1 || instructors?.length === 0
                ? "Instructors"
                : "Instructor"}{" "}
              <span className="badge">{instructors?.length}</span>
            </h2>
          </div>
        </div>
        <div className="list">
          {!instructors && <div />}
          {instructors?.length > 0 ? (
            instructors.map((instructor) => (
              <InstructorEmailBox
                instructor={instructor}
                key={instructor.userId}
              ></InstructorEmailBox>
            ))
          ) : (
            <ShortcutAction className="no-data-message" disabled>
              <SlashCircleIcon></SlashCircleIcon>
              No instructors assigned to this classroom.
            </ShortcutAction>
          )}
        </div>
        <div className="insights-header">
          <div className="title-section">
            <h2 className="title">{"Careers"}</h2>
            {!isDesktop && (
              <StatusDropdownList
                className="insights-simulations-filter"
                masteryStats={masteryStats}
                dataGroupedByOccupation={dataGroupedByOccupation}
                onStatusSelected={onStatusSelected}
                selectedStatus={selectedStatus}
                responsiveStyles={params}
              ></StatusDropdownList>
            )}
          </div>
        </div>
        {isDesktop && masteryStats && dataGroupedByOccupation && (
          <MyWorkDataboxes
            className="insights-databoxes"
            masteryStats={masteryStats}
            onStatusSelected={onStatusSelected}
            dataGroupedByOccupation={dataGroupedByOccupation}
            responsiveStyles={params}
          ></MyWorkDataboxes>
        )}
      </div>
      <div className="simulations-section">
        {filteredData && filteredData?.length > 0 && (
          <MyWorkCeSimulationsTable
            responsiveStyles={params}
            sims={filteredData}
          />
        )}
        {filteredData?.length === 0 && (
          <ShortcutAction className="no-data-message" disabled>
            <SlashCircleIcon></SlashCircleIcon>
            No data to display. Please try adjusting your search or filters.
          </ShortcutAction>
        )}
        {!filteredData && (
          <div>
            <Loader height={280}></Loader>
          </div>
        )}
      </div>
    </>
  );
}
