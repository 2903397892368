export const Terms = {
  version: 1,
  url: "https://transfrinc.com/terms/",
};

export const PrivacyPolicy = {
  url: "https://transfrinc.com/privacy/",
};

export const TrekFeedbackForm = {
  url: "http://bit.ly/trekfeedback",
};
